<template>
  <!-- Table Container Card -->
  <b-overlay v-if="show == false" :show="show" rounded="sm" no-fade>
    <div>
      <b-card no-body>
        <div class="container mt-3">
       
        </div>
        <!-- small : {{ small }} -->

        <!-- {{checkbox_submit}} -->
        <!-- {{ num_test }} -->
        <!-- {{data_save}} -->

        <!-- tab 2 -->
      

        <!-- {{ update_data }} -->
        <!-- {{ totalInvoices1 }} -->
        <!-- {{ fetchInvoices }}  -->
        <!-- {{ fetchInvoices() }} -->
        <!-- <h1>Skor :  {{fetchInvoices[0].item}}</h1> -->

  

        <template>
          <div>
            <b-modal ref="my-modal1" hide-footer title="อัพเดท">
              <div class="d-block">
                <h6>คุณต้องการอัพเดทข้อมูลหรือไม่</h6>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="Update"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hideModal"
                >
                  ปิด
                </button>
              </div>
            </b-modal>
          </div>
        </template>

        <template>
          <div>
            <b-modal ref="my-modal2" hide-footer title="บันทึก">
              <div class="d-block">
                <h6>คุณต้องการบันทึกข้อมูลหรือไม่</h6>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="submitDetail(submit_t)"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hideModal"
                >
                  ปิด
                </button>
              </div>
            </b-modal>
          </div>
        </template>

        <template>
          <div>
            <b-modal ref="submit" hide-footer title="สร้างรายการ">
              <!-- {{options_shopname}} -->
              <div class="d-block">
                <!-- <select class="form-control" v-model="formData_modal.custCode">
                
                  <option
                    v-for="option in options_shopname"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select> -->

                <!-- {{ options_new }} -->

                <!-- {{ formData_modal.custCode}} -->
                <v-select
                  v-model="formData_modal.custCode"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options_new"
                  class="col-12"
                  placeholder="ชื่อร้านค้า"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>

                <b-row class="my-1">
                  <b-col sm="2">
                    <label for="input-small">Small:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-input
                      id="input-small"
                      type="number"
                      size="sm"
                      placeholder="Enter your small"
                      v-model="formData_modal.sizeSmall"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col sm="2">
                    <label for="input-small">Middle:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-input
                      id="input-small"
                      type="number"
                      size="sm"
                      placeholder="Enter your middle"
                      v-model="formData_modal.sizeMiddle"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col sm="2">
                    <label for="input-small">Large:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-input
                      id="input-small"
                      type="number"
                      size="sm"
                      placeholder="Enter your large"
                      v-model="formData_modal.sizeLarge"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col sm="2">
                    <label for="input-small">remark:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-input
                      id="input-small"
                      size="sm"
                      placeholder="Enter your Remark"
                      v-model="formData_modal.remark"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="submitCreateNew"
                  v-if="formData_modal.custCode != ``"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  v-else
                  disabled
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hideModal"
                >
                  ปิด
                </button>
              </div>
            </b-modal>
          </div>
        </template>

        <!-- <template #cell(actions1)="data">
            <div class="text-nowrap">
                <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="SendIcon"
                    class="cursor-pointer"
                    size="16"
                />
            </div> </template> -->
        <!-- </b-card> -->
        <!-- </template> -->
       
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BCollapse,
  BFormCheckbox,
  BFormCheckboxGroup,
  BOverlay,
  BCardText,
} from "bootstrap-vue";
import { useWindowScroll } from "@vueuse/core";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useInvoicesList from "./useInvoiceList";
import axios from "axios";
import invoiceStoreModule from "../invoiceStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormDatepicker,
    vSelect,
    flatPickr,
    BCollapse,
    BFormCheckbox,
    BFormCheckboxGroup,
    BOverlay,
    ToastificationContent,
    BCardText,
  },
  computed: {
    options_route: () => options_route,
  },
  data() {
    return {
      selected: [],
      checkbox_submit: ``,
      data_save: [],
      show: false,
      message: "u",
      submit_t: "",
      num_test: 0,
      // fields:""
      // fields: {
      // No: "index",
      // },
      fields: {
        No: "index",
        รหัสรายการ: "code",
        สาย: "transfer_zone",
        วันส่งสินค้า: "order_date",
        รหัส: "shop_code",
        ร้านค้า: "shop_name",
        "สินค้า(เล็ก)": "size_small",
        "สินค้า(กลาง)": "size_middle",
        "สินค้า(ใหญ่)": "size_large",
        อื่นๆ: "remark",
        รวม: "total",
        "สถานะ จัดออเดอร์": "status_order",
        สถานะ: "status_main",
      },
      options: [],
      options_route: [],
      options_carLicense: [],
      options_shopname: [{ text: "ระบุชื่อร้าน", value: "" }],
      options_new: [],
      small: 0,
      middle: 0,
      large: 0,
      checked: false,
      formData_modal: {
        custCode: "",
        code: "",
        sizeSmall: 0,
        sizeMiddle: 0,
        sizeLarge: 0,
        remark: "",
      },
      total: {
        small: 0,
        middle: 0,
        large: 0,
      },
      items: [
        {
          isActive: true,
          age: 40,
          name: { first: "Dickerson", last: "Macdonald" },
        },
        {
          isActive: false,
          age: 21,
          name: { first: "Larsen", last: "Shaw" },
        },
        {
          isActive: false,
          age: 9,
          name: { first: "Mini", last: "Navarro" },
          _rowVariant: "success",
        },
        {
          isActive: false,
          age: 89,
          name: { first: "Geneva", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "Jami", last: "Carney" },
        },
        {
          isActive: false,
          age: 27,
          name: { first: "Essie", last: "Dunlap" },
        },
        {
          isActive: true,
          age: 40,
          name: { first: "Thor", last: "Macdonald" },
        },
        {
          isActive: true,
          age: 87,
          name: { first: "Larsen", last: "Shaw" },
          _cellVariants: { age: "danger", isActive: "warning" },
        },
        {
          isActive: false,
          age: 26,
          name: { first: "Mitzi", last: "Navarro" },
        },
        {
          isActive: false,
          age: 22,
          name: { first: "Genevieve", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "John", last: "Carney" },
        },
        {
          isActive: false,
          age: 29,
          name: { first: "Dick", last: "Dunlap" },
        },
      ],
      // fields: [
      //   {
      //     key: "name",
      //     label: "Person full name",
      //     sortable: true,
      //     sortDirection: "desc",
      //   },
      //   {
      //     key: "age",
      //     label: "Person age",
      //     sortable: true,
      //     class: "text-center",
      //   },
      //   {
      //     key: "isActive",
      //     label: "Is Active",
      //     formatter: (value, key, item) => {
      //       return value ? "Yes" : "No";
      //     },
      //     sortable: true,
      //     sortByFormatted: true,
      //     filterByFormatted: true,
      //   },
      //   { key: "actions", label: "Actions" },
      // ],
      formData: {
        searchStartDate: "",
        carLicense: "",
        searchRob: "",
      },

      totalRows: 1,
      selected: "A",
      options_test: [
        { text: "One", value: "A" },
        { text: "Two", value: "B" },
        { text: "Three", value: "C" },
      ],
      //   currentPage: 1,
      //   perPage: 5,
      //   pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      update_data: [],
      check_button: [],
      check_button1: false,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      date_update: [],
    };
  },
  //     watch: {
  //         totalInvoices1: {
  //     handler(newName, oldName) {
  //       console.log('obj.a changed');
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  watch: {
    // formData_modal.custCode

    formData_modal: {
      // console.log("")
      handler(val) {
        console.log(val);

        if (val.custCode) {
          console.log("1");
        } else {
          console.log("2");
        }
        // console.log(val);
        // console.log("val" , val)
        // console.log("val" , val)
        // this.UpdateQ(val);

        // for (let i = 0; i < this.options_shopname.length; i++) {
        //     if (val.custCode == this.options_shopname[i].text) {
        //         console.log("test1" , this.options_shopname[i].value);
        //         // this.formData_modal.custCode  = this.options_shopname[i].value
        //         break;
        //         // return
        //     } else {
        //         console.log("test2");
        //     }
        // }

        // for (let i = 0; i < val.length; i++) {

        //     if(val[i].checkbox === true){

        //         // if(val[i].code == this.update_data){

        //         // }

        //         this.update_data.push( val[i])
        //         console.log("val" , val[i])
        //         return 0;

        //     }

        // this.options.push(response.data[i].shopName);
        // }
      },
      deep: true,
    },
    totalInvoices1: {
      // console.log("")
      handler(val) {
        // console.log(val);
        // console.log("val" , val)
        // console.log("val" , val)
        this.UpdateQ(val);

        // for (let i = 0; i < val.length; i++) {
        //     if (val[i].checkbox == true) {
        //         console.log("test1");
        //         // return
        //     } else {
        //         console.log("test2");
        //     }
        // }

        // for (let i = 0; i < val.length; i++) {

        //     if(val[i].checkbox === true){

        //         // if(val[i].code == this.update_data){

        //         // }

        //         this.update_data.push( val[i])
        //         console.log("val" , val[i])
        //         return 0;

        //     }

        // this.options.push(response.data[i].shopName);
        // }
      },
      deep: true,
    },
    formData: {
      handler(val) {
        // console.log(val);
        if (
          this.formData.searchStartDate != "" &&
          this.formData.carLicense != "" &&
          this.formData.searchRob != "" &&
          this.formData.searchStartDate != null &&
          this.formData.carLicense != null &&
          this.formData.searchRob != null
        ) {
          //   console.log("1");
          this.check_button1 = true;
        } else {
          //   console.log("2");
          this.check_button1 = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    check(e) {
      console.log("test", e);
      if (e != 0) {
        this.submit_t = e;
        this.$refs["my-modal2"].show();
        console.log("1");
      } else {
        this.$refs["my-modal1"].show();
        console.log("2");
      }
    },

    hide(e) {
      //   console.log("test");
      (this.formData_modal = {
        custCode: "",
        code: "",
        sizeSmall: 0,
        sizeMiddle: 0,
        sizeLarge: 0,
        remark: "",
      }),
        this.$refs["my-modal1"].hide();
    },
    hideModal() {
      (this.formData_modal = {
        custCode: "",
        code: "",
        sizeSmall: 0,
        sizeMiddle: 0,
        sizeLarge: 0,
        remark: "",
      }),
        this.$refs["submit"].hide();
      this.$refs["my-modal1"].hide();
      this.$refs["my-modal2"].hide();
    },
    async CheckRegister() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listshop`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options.push(response.data[i].shopName);
        }

        for (let j = 0; j < response.data.length; j++) {
          let obj = {
            text: response.data[j].shopName,
            value: response.data[j].code,
          };

          // res.data[i].branchName
          //   text += cars[i] + "<br>";
          this.options_shopname.push(obj);
          this.options_new.push(response.data[j].shopName);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },

    async submitDetail(e) {
      console.log("eeee",e)
      this.show = true;
      let payload = {
        custCode: e.shop_code,
        code: e.code,
        sizeSmall: e.max_small ? e.max_small :0,
        sizeMiddle: e.max_middle  ? e.max_middle :0,
        sizeLarge: e.max_large  ? e.max_large :0,
        invoice: e.invoice? e.invoice:"",
        amount: e.amount ? e.amount : 0,
        remark: e.remark,
        transferDate: this.formData.searchStartDate,
        license: this.formData.carLicense,
        round: this.formData.searchRob,
        staffId: 1,
        rdSend : this.currentPortal == 1 ? "D" : "T"
        // row: b,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/editorder`,
          payload
        );

        if (response.data.status == "Save success") {
          (this.formData_modal = {
            custCode: "",
            code: "",
            sizeSmall: 0,
            sizeMiddle: 0,
            sizeLarge: 0,
            remark: "",
            invoice: "",
            amount: "",
          }),
            // this.ClearData();
            // this.ClearDataUpdate();
            this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          // this.$refs["submit"].hide();

          // this.show = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `บันทึกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });

          this.show = false;

          // this.show = false;
        }

        // for (let i = 0; i < response.data.length; i++) {
        //   this.options.push(response.data[i].shopName);
        // }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },

    async createNew(e) {
      this.$refs["submit"].show();
    },
    async submitCreateNew(e) {
      this.show = true;
      console.log("test", e);
      // this.$refs["submit"].show();
      // this.$refs["submit"].hide();
      // this.show = true;
      // this.$refs["submit"].show();
      // let payload = {
      // custCode: e.shop_code,
      // sizeSmall: e.max_small,
      // sizeMiddle: e.max_middle,
      // sizeLarge: e.max_large,
      // remark: e.remark
      // };

      for (let i = 0; i < this.options_shopname.length; i++) {
        if (this.formData_modal.custCode == this.options_shopname[i].text) {
          console.log("test1", this.options_shopname[i].value);
          this.formData_modal.custCode = this.options_shopname[i].value;
          break;
          // return
        } else {
          console.log("test2");
        }
      }

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/saveorder`,
          this.formData_modal
        );
        if (response.data.status == "Save success") {
          (this.formData_modal = {
            custCode: "",
            code: "",
            sizeSmall: 0,
            sizeMiddle: 0,
            sizeLarge: 0,
            remark: "",
          }),
            (this.options_new = []);
          // this.ClearData();
          // this.ClearDataUpdate();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          // this.$refs["submit"].hide();

          // this.show = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `บันทึกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });

          this.show = false;
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    async getCar() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listCar`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options_carLicense.push(response.data[i].carLicense);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    async getBranch() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listBranch`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          // this.options.push(response.data[i].shopName);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    async getRoute() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listRoute`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options_route.push(response.data[i].route);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    async UpdateAll(e, items) {
      console.log(e, items);
      console.log("update_data", this.update_data);

      this.show = true;
      //   console.log("e555", e, items);

      // this.date_update = e;

      // this.update_data = e;

      // console.log("update_data",update_data)

      //   console.log("this.update_datatttttttttttttttttttt", this.update_data);
      //   console.log("this.date_update ", this.date_update);

      let b = [];

      // this.date_update

      //   console.log("this.update_data", this.date_update);

      for (var i = 0; i < this.update_data.length; i++) {
        console.log("checkall", this.update_data[i].checkAll);
        if (this.update_data[i].checkAll == true) {
          this.update_data[i].max_small = this.update_data[i].size_small;
          this.update_data[i].max_middle = this.update_data[i].size_middle;
          this.update_data[i].max_large = this.update_data[i].size_large;
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          b.push(this.update_data[i]);
          this.data_save.push(this.update_data[i]);

          // if(this.data_save != ``){

          //   for (let j = 0; j < this.dava_save.length; j++) {
          //             if(this.update_data[i].code == this.dava_save[j].code){
          //               console.log( "1")
          //             }

          //           }
          // }

          // this.data_save.push(this.update_data[i]);
        } else {
          //   console.log("2");
        }
        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      console.log("22222222", b);

      this.check_button = b;
      this.checkbox_submit = b;
      // this.data_save = b;
      console.log("111111");

      let num_small = 0;
      let num_middle = 0;
      let num_large = 0;

      console.log("checkbox_submit", this.checkbox_submit);

      for (let i = 0; i < this.checkbox_submit.length; i++) {
        num_small = num_small + parseInt(this.checkbox_submit[i].size_small);
        num_middle = num_middle + parseInt(this.checkbox_submit[i].size_middle);
        num_large = num_large + parseInt(this.checkbox_submit[i].size_large);
        // this.total.small = num_small;
        // console.log("num" , num_small)
      }

      this.small = num_small;
      this.middle = num_middle;
      this.large = num_large;

      console.log("total", this.small);

      // console.log("num1" , num)
      // total.small = total.small + this.check_button[i].max_small
      // if (this.check_button[i].max_small == true) {
      // console.log("1" ,  this.date_update[i]);
      // this.update_data[i].size_small = `10`
      // this.update_data[i].size_middle = `10`
      // this.update_data[i].size_large = `10`
      // this.update_data[i].max_large = this.date_update[i].max_large
      // b.push(this.update_data[i]);
      // } else {
      //     console.log("2");
      // }
      //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      // }

      console.log("test01", this.total);

      this.show = false;

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      // //             }
      //         console.log("test" ,e ,x )

      //         if(e == true ){

      //             this.update_data.push(x)
      //         }else{

      //             //เช็ค x.code ว่ามีในนี้ไหม  update_data

      //             for (let i = 0; i < this.update_data.length; i++) {
      //                 console.log("x.code ", x.code  , this.update_data[i].code)

      //                 if(x.code == this.update_data[i].code){
      //                     console.log("1")
      //                     // this.update_data.splice(this.update_data.indexOf( x.code  ), 1);
      //                 }else{
      //                     console.log("2")
      //                 }

      //                 // this.options.push(response.data[i].shopName);
      //             }

      //             //ถ้ามี ลบ update_data.code
      //         console.log("555",this.update_data)

      //         }

      //         console.log("update_data", this.update_data)

      // try {
      //     const response = await axios.post(
      //         `http://202.129.206.107:11009/listshop`
      //     );

      //     console.log("response", response);

      //     for (let i = 0; i < response.data.length; i++) {
      //         this.options.push(response.data[i].shopName);
      //     }

      // } catch (errors) {
      //     console.error(errors);
      // }
    },

    async UpdateQ(e, items) {
      // console.log("test",e,items)
      this.show = true;
      //   console.log("e555", e, items);

      // this.date_update = e;

      this.update_data = e;

      console.log("e555", e, this.update_data);

      //   console.log("this.update_datatttttttttttttttttttt", this.update_data);
      //   console.log("this.date_update ", this.date_update);

      let b = [];

      // this.date_update

      //   console.log("this.update_data", this.date_update);

      for (var i = 0; i < this.update_data.length; i++) {
        if (this.update_data[i].checkbox == true) {
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          b.push(this.update_data[i]);
          // this.data_save.push(this.update_data[i]);
        } else {
          //   console.log("2");
        }
        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      //   console.log("22222222", b);
      this.check_button = b;

      let num_small = 0;
      let num_middle = 0;
      let num_large = 0;

      for (let i = 0; i < this.check_button.length; i++) {
        num_small = num_small + parseInt(this.check_button[i].max_small);
        num_middle = num_middle + parseInt(this.check_button[i].max_middle);
        num_large = num_large + parseInt(this.check_button[i].max_large);
        // console.log("num" , num)
      }

      this.total.small = num_small;
      this.total.middle = num_middle;
      this.total.large = num_large;

      // console.log("num1" , num)
      // total.small = total.small + this.check_button[i].max_small
      // if (this.check_button[i].max_small == true) {
      // console.log("1" ,  this.date_update[i]);
      // this.update_data[i].size_small = `10`
      // this.update_data[i].size_middle = `10`
      // this.update_data[i].size_large = `10`
      // this.update_data[i].max_large = this.date_update[i].max_large
      // b.push(this.update_data[i]);
      // } else {
      //     console.log("2");
      // }
      //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      // }
      // this.data_save.push(this.checkbox_submit)
      this.num_test = this.num_test + 1;

      console.log("test01", this.num_test);
      this.show = false;

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      // //             }
      //         console.log("test" ,e ,x )

      //         if(e == true ){

      //             this.update_data.push(x)
      //         }else{

      //             //เช็ค x.code ว่ามีในนี้ไหม  update_data

      //             for (let i = 0; i < this.update_data.length; i++) {
      //                 console.log("x.code ", x.code  , this.update_data[i].code)

      //                 if(x.code == this.update_data[i].code){
      //                     console.log("1")
      //                     // this.update_data.splice(this.update_data.indexOf( x.code  ), 1);
      //                 }else{
      //                     console.log("2")
      //                 }

      //                 // this.options.push(response.data[i].shopName);
      //             }

      //             //ถ้ามี ลบ update_data.code
      //         console.log("555",this.update_data)

      //         }

      //         console.log("update_data", this.update_data)

      // try {
      //     const response = await axios.post(
      //         `http://202.129.206.107:11009/listshop`
      //     );

      //     console.log("response", response);

      //     for (let i = 0; i < response.data.length; i++) {
      //         this.options.push(response.data[i].shopName);
      //     }

      // } catch (errors) {
      //     console.error(errors);
      // }
    },
    async Update() {
      this.show = true;
      // console.log("e555" , e)

      // this.update_data = e

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      //             }
      // console.log("test" , totalInvoices1)

      //     console.log("this.update_data",   this.update_data)

      let b = [];
      // this.$refs["my-modal1"].show();

      // this.date_update

      console.log("this.update_data", this.update_data);

      for (var i = 0; i < this.update_data.length; i++) {
        if (this.update_data[i].checkAll == true) {
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          b.push(this.update_data[i]);
        } else {
          //   console.log("2");
        }
        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      //      console.log("this.update_data[i] " , this.update_data[i] )

      // let test = {
      //     id: 18,
      //     code: "P00005",
      //     orderDate: "2023-03-23 00:35:35",
      //     dueDate: null,
      //     transferDate: "2023-03-23 00:35:35",
      //     custCode: "6603002",
      //     sizeSmall: "12455",
      //     sizeMiddle: 1881,
      //     sizeLarge: 1112,
      //     remark: "ttyy",
      //     total: 4238,
      //     statusOrder: "ปกติ",
      //     statusMain: "รอจัดรถ",
      //     updateDate: "2023-03-29 14:19:48",
      //     lineId: "Uafc47e26b9bb051412f9ecbf0c06346b",
      //     chatId: "U65410582defe7d3b679c62670ad6de44",
      // };
      //   console.log(" b", b);
      // console.log(" test", b);

      // if(e == true ){

      //     this.update_data.push(x)
      // }

      let payload = {
        transferDate: this.formData.searchStartDate,
        license: this.formData.carLicense,
        round: this.formData.searchRob,
        staffId: 1,
        row: b,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/savetransfer`,
          payload
        );

        if (response.data.status == "Save success") {
          // this.ClearData();
          // this.ClearDataUpdate();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();

          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `บันทึกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    ClearData() {
      //   console.log("1", this.searchStartDate);
      this.searchStartDate = "";
      this.searchEndDate = "";
      // this.searchRout = "";
      this.searchStatus = "";
      this.searchStatusMain = "รอจัดรถ";
      this.searchRoute = [];
      this.statusFilter = "";
      this.checkbox_submit = "";
      this.small = 0;
      this.middle = 0;
      this.large = 0;
    },
    ClearDataUpdate() {
      this.formData = {
        searchStartDate: "",
        carLicense: "",
        searchRob: "",
      };
      // console.log("1", this.searchStartDate);
      // this.searchStartDate = "";
      // this.searchEndDate = "";
      // this.searchRout = "";
      // this.searchStatus = "";
      // this.searchRoute = "";
      // this.statusFilter = "";
    },
    // total() {
    //   console.log("test");
    // },
  },
  mounted() {
    this.CheckRegister();
    this.getCar();
    this.getBranch();
    this.getRoute();

    // console.log("1");
  },
  setup() {
    const { y } = useWindowScroll();

    const portals = [
      {
        id: 1,
        name: "ส่งวันนี้",
      },
      {
        id: 2,
        name: "ส่งพรุ่งนี้",
      },
    ];

    const currentPortal = portals[1];

    const scrollToTop = () => {
      const rootEle = document.documentElement;
      rootEle.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    // const statusOptions = [
    //   'Downloaded',
    //   'Draft',
    //   'Paid',
    //   'Partial Payment',
    //   'Past Due',
    // ]

    //const statusOptions = ["ด่วนมาก", "ด่วน", "ปกติ"];
    const statusOptions = ["ปกติ", "เร่งด่วน", "ด่วนที่สุด"];
    const statusMainOptions = ["รอจัดรถ", "จัดรถแล้ว"];
    const Optionsrob = ["1", "2", "3", "4", "5"];

    // const statusOptionsshop = [
    // ]

    const {
      fetchInvoices,
      fetch_shop,
      tableColumns,
      perPage,
      currentPage,
      excel,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      totalInvoices5,
      max_large,
      max_middle,
      max_small,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchStartDate,
      searchEndDate1,
      searchEndDate,
      searchStatus,
      searchStatusMain,
      searchRoute,
      searchRob,

      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      params,
      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList();

    return {
      fetchInvoices,
      portals,
      currentPortal,
      fetch_shop,
      tableColumns,
      perPage,
      currentPage,
      excel,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      totalInvoices5,
      max_large,
      max_middle,
      max_small,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      searchEndDate1,
      searchStartDate,
      searchEndDate,
      searchStatus,
      searchStatusMain,
      searchRoute,
      searchRob,

      statusFilter,
      //   statusOptionsshop,
      Optionsrob,
      refetchData,
      params,
      statusOptions,
      statusMainOptions,
      y,
      scrollToTop,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.hidden_header {
  display: none;
}
.per-page-selector {
  width: 90px;
}

// .btn-scroll-to-top {
//   position: fixed;
//     bottom: 5%;
//     right: 30px;
//     z-index: 99;

//     opacity: 0;
//     // transform: translateY(100px);
//     transition: all .5s ease;

//     &.show {
//       opacity: 1;
//       // transform: translateY(0)
//     }
// }
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

// .invoice-filter-select[data-v-5cb60a6f] {
//     min-width: 213px;
// }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// table, td, th {
//   border: 1px solid black;
// }

table {
  //   border-collapse: collapse;
  width: 120%;
}

th {
  height: 70px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7;
  text-align: center;
}
[dir] .table th,
[dir] .table td {
  // padding: 0.72rem 2rem;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 0.4rem;
  text-align: center;
}
</style>
